<template>
  <div class="phoneTable">
    <div style="display: flex; position: relative">
      <div
        class="btn"
        :class="this.orderType == '01' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('01')"
      >
        累计询价排行
      </div>
      <div
        class="btn"
        :class="this.orderType == '02' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('02')"
      >
        累计成交排行
      </div>
      <div
        class="btn"
        :class="this.orderType == '03' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('03')"
      >
        毛利贡献排行
      </div>
      <!-- <div
        class="btn btnType1"
        @click="exportExcel"
        style="
          padding: 0 20px;
          position: absolute;
          right: 0;
          width: unset;
          margin-right: 5px;
        "
      >
        表格导出
      </div> -->
    </div>
    <el-form inline size="small" style="margin-top: 20px">
      <el-form-item>
        <el-select
          size="small"
          v-model="searchForm.partitionval"
          placeholder="请选择总监/区域"
          clearable
        >
          <el-option
            v-for="item in partition"
            :key="item.name"
            :value="item.staffId"
            :label="
              item.areaName
                ? `${item.staffName}-${item.areaName}`
                : item.staffName
            "
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchForm.tagId"
          placeholder="选择门店标签"
          size="small"
          clearable
          filterable
        >
          <el-option
            v-for="item in selectTagList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchForm.storeId"
          placeholder="选择所属门店"
          size="small"
          clearable
          filterable
        >
          <el-option
            v-for="item in selectStoreList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          v-for="item in dateTypeOption"
          :key="item.value"
          class="btn"
          :type="dateType == item.value ? 'primary' : 'default'"
          @click="ClickDateType(item.value)"
        >{{item.label}}</el-button>
      </el-form-item>
      <el-form-item>
        <div class="el-date-time" style="margin: 0;">
          <el-date-picker
            v-model="valueTime"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="el-date-time" style="margin: 0;">
          <span class="add_time" @click="addTimeChoose">{{addTimeText}}</span>
          <el-date-picker
            style="margin-left: 30px;"
            v-model="thanValueTime"
            :picker-options="pickerOptions"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="handleCurrentChange"
            v-if="addTimeTrue"
            >
          </el-date-picker>
          <span @click="addTimeCancle" v-if="addTimeTrue == true"><img src="../../../../assets/images/home/-s-zy_ico_close.png" class="close_img"></span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="handleCurrentChange">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      max-height="450"
      :data="tableData"
      border
      v-loading="phoneTableLoading"
      style="width: 100%"
    >
      <template slot="empty">
        <div class="table_empty">
          <img
            src="../../../../assets/images/tjsjtb.png"
            style="width:200px;height:100px"
            alt=""
          />
          <span style="font-size:12px;line-height:12px;padding-left:12px"
            >暂无数据</span
          >
        </div>
      </template>
      <el-table-column label="排行" type="index" align="center" width="70">
        <template slot-scope="{ $index }">
          <div class="numBG_1" v-if="$index === 0">1</div>
          <div class="numBG_2" v-else-if="$index === 1">2</div>
          <div class="numBG_3" v-else-if="$index === 2">3</div>
          <div v-else>{{ $index + 1 }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="name"
        label="门店"
        align="center"
      ></el-table-column> -->
      <el-table-column align="center" label="门店">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.name"
            placement="top"
          >
            <div class="Remarks">
              <span
                style="
              cursor: pointer;
              color: rgba(9, 129, 255, 1);
              border-bottom: 1px solid rgba(9, 129, 255, 1);
            "
                @click="clickStoreTableDia(scope.row)"
                >{{ scope.row.name }}</span
              >
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="staffCount"
        label="门店人数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="inquiry"
        label="询价量"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.inquiry.chooseData }}</span>
          <div v-if="scope.row.inquiry.thanUp">
            <div v-if="scope.row.inquiry.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.inquiry.thanData }}%</div>
            <div v-else-if="scope.row.inquiry.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.inquiry.thanData }}%</div>
            <div v-else-if="scope.row.inquiry.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.inquiry.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column prop="avgInquiry" label="平均询价量" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>平均询价量</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="平均询价量=门店总询价量/门店人数"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.avgInquiry }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.avgInquiry.chooseData }}</span>
          <div v-if="scope.row.avgInquiry.thanUp">
            <div v-if="scope.row.avgInquiry.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.avgInquiry.thanData }}%</div>
            <div v-else-if="scope.row.avgInquiry.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.avgInquiry.thanData }}%</div>
            <div v-else-if="scope.row.avgInquiry.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.avgInquiry.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="quote"
        label="报价量"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.quote.chooseData }}</span>
          <div v-if="scope.row.quote.thanUp">
            <div v-if="scope.row.quote.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.quote.thanData }}%</div>
            <div v-else-if="scope.row.quote.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.quote.thanData }}%</div>
            <div v-else-if="scope.row.quote.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.quote.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column label="累计成交量" align="center">
        <template slot-scope="scope">
          <span  style="
              cursor: pointer;
              color: rgba(9, 129, 255, 1);
              border-bottom: 1px solid rgba(9, 129, 255, 1);
            "
            @click="ClickStoreDiaNum(scope.row)">{{ scope.row.deal.chooseData }}</span>
          <div v-if="scope.row.deal.thanUp">
            <div v-if="scope.row.deal.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.deal.thanData }}%</div>
            <div v-else-if="scope.row.deal.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.deal.thanData }}%</div>
            <div v-else-if="scope.row.deal.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.deal.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="压价单量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.depreciateNum.chooseData }}</span>
          <div v-if="scope.row.depreciateNum.thanUp">
            <div v-if="scope.row.depreciateNum.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.depreciateNum.thanData }}%</div>
            <div v-else-if="scope.row.depreciateNum.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.depreciateNum.thanData }}%</div>
            <div v-else-if="scope.row.depreciateNum.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.depreciateNum.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="压价订单占比" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.depreciateRate.chooseData }}%</span>
          <div v-if="scope.row.depreciateRate.thanUp">
            <div v-if="scope.row.depreciateRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.depreciateRate.thanData }}%</div>
            <div v-else-if="scope.row.depreciateRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.depreciateRate.thanData }}%</div>
            <div v-else-if="scope.row.depreciateRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.depreciateRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="成交率" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.dealRate.chooseData }}%</span>
          <div v-if="scope.row.dealRate.thanUp">
            <div v-if="scope.row.dealRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dealRate.thanData }}%</div>
            <div v-else-if="scope.row.dealRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dealRate.thanData }}%</div>
            <div v-else-if="scope.row.dealRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dealRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cancelRate" label="取消率" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>取消率</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="取消率=取消订单量/总询价量*100%"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.cancelRate.chooseData }}%</span>
          <div v-if="scope.row.cancelRate.thanUp">
            <div v-if="scope.row.cancelRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.cancelRate.thanData }}%</div>
            <div v-else-if="scope.row.cancelRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.cancelRate.thanData }}%</div>
            <div v-else-if="scope.row.cancelRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.cancelRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="refundOrAbolishRate"
        label="退单/作废率"
        align="center"
      >
        <template slot="header">
          <div class="img-el-heard">
            <div>退单/作废率</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="退单率=退单量/成交量*100%"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.refundOrAbolishRate || 0 }}%</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.refundOrAbolishRate.chooseData }}%</span>
          <div v-if="scope.row.refundOrAbolishRate.thanUp">
            <div v-if="scope.row.refundOrAbolishRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.refundOrAbolishRate.thanData }}%</div>
            <div v-else-if="scope.row.refundOrAbolishRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.refundOrAbolishRate.thanData }}%</div>
            <div v-else-if="scope.row.refundOrAbolishRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.refundOrAbolishRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="dealAmount"
        label="累计交易额"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.dealAmount.chooseData }}</span>
          <div v-if="scope.row.dealAmount.thanUp">
            <div v-if="scope.row.dealAmount.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
            <div v-else-if="scope.row.dealAmount.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
            <div v-else-if="scope.row.dealAmount.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <!-- <el-table-column
        prop="grossProfit"
        label="毛利贡献"
        align="center"
      ></el-table-column> -->
      <el-table-column prop="useAddPrice" label="加价金额" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>加价金额</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="加价金额=返利加价金额+红包加价金额"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.useAddPrice || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.useAddPrice.chooseData }}</span>
          <div v-if="scope.row.useAddPrice.thanUp">
            <div v-if="scope.row.useAddPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
            <div v-else-if="scope.row.useAddPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
            <div v-else-if="scope.row.useAddPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="forcePriceAll" label="总压价金额" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总压价金额</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="总压价金额=回收商报价-返利-红包-顾客成交价"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.forcePriceAll.chooseData }}</span>
          <div v-if="scope.row.forcePriceAll.thanUp">
            <div v-if="scope.row.forcePriceAll.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.forcePriceAll.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAll.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAll.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAll.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAll.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grossAll" label="总毛利" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总毛利</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="总毛利=回收商报价-顾客成交价"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.grossAll.chooseData }}</span>
          <div v-if="scope.row.grossAll.thanUp">
            <div v-if="scope.row.grossAll.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.grossAll.thanData }}%</div>
            <div v-else-if="scope.row.grossAll.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.grossAll.thanData }}%</div>
            <div v-else-if="scope.row.grossAll.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.grossAll.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rebate" label="公司净利" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>公司净利</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="公司净利=公司返利利润+公司压价利润，扣除员工红包提成"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.rebate || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.rebate.chooseData }}</span>
          <div v-if="scope.row.rebate.thanUp">
            <div v-if="scope.row.rebate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebate.thanData }}%</div>
            <div v-else-if="scope.row.rebate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebate.thanData }}%</div>
            <div v-else-if="scope.row.rebate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rebateAfter" label="返利利润" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>返利利润</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="返利利润=报价预留的利润比例*报价金额，扣除加价用掉的利润"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.rebateAfter || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.rebateAfter.chooseData }}</span>
          <div v-if="scope.row.rebateAfter.thanUp">
            <div v-if="scope.row.rebateAfter.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
            <div v-else-if="scope.row.rebateAfter.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
            <div v-else-if="scope.row.rebateAfter.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rebateDepreciate" label="公司压价利润" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>公司压价利润</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="公司压价利润=全部压价金额-员工压价提成"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.rebateDepreciate || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.rebateDepreciate.chooseData }}</span>
          <div v-if="scope.row.rebateDepreciate.thanUp">
            <div v-if="scope.row.rebateDepreciate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
            <div v-else-if="scope.row.rebateDepreciate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
            <div v-else-if="scope.row.rebateDepreciate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="公司压价利润占比" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.forcePriceAllRate.chooseData }}%</span>
          <div v-if="scope.row.forcePriceAllRate.thanUp">
            <div v-if="scope.row.forcePriceAllRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.forcePriceAllRate.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAllRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAllRate.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAllRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAllRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="staffReward" label="员工奖励" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>员工奖励</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="员工奖励=店长店员获得的成交红包+差价提成+创收奖励，未下单门店不计入统计。"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.staffReward }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.staffReward.chooseData }}</span>
          <div v-if="scope.row.staffReward.thanUp">
            <div v-if="scope.row.staffReward.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
            <div v-else-if="scope.row.staffReward.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
            <div v-else-if="scope.row.staffReward.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grossAll" label="员工红包" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>员工红包</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="员工红包=成交订单发放给店长和店员的红包金额"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.staffAngPow.chooseData }}</span>
          <div v-if="scope.row.staffAngPow.thanUp">
            <div v-if="scope.row.staffAngPow.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
            <div v-else-if="scope.row.staffAngPow.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
            <div v-else-if="scope.row.staffAngPow.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grossAll" label="员工差价提成" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>员工差价提成</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="员工差价提成=总压价金额-公司压价利润"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.staffCut.chooseData }}</span>
          <div v-if="scope.row.staffCut.thanUp">
            <div v-if="scope.row.staffCut.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffCut.thanData }}%</div>
            <div v-else-if="scope.row.staffCut.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffCut.thanData }}%</div>
            <div v-else-if="scope.row.staffCut.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffCut.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="afterSubsidyQuotePriceAll" label="总回收金额（含补贴）" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总回收金额（含补贴）</div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.afterSubsidyQuotePriceAll }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="renewSubsidyPriceAll" label="换新补贴金额" align="center">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.renewSubsidyPriceAll }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.renewSubsidyPriceAll.chooseData }}</span>
          <div v-if="scope.row.renewSubsidyPriceAll.thanUp">
            <div v-if="scope.row.renewSubsidyPriceAll.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.renewSubsidyPriceAll.thanData }}%</div>
            <div v-else-if="scope.row.renewSubsidyPriceAll.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.renewSubsidyPriceAll.thanData }}%</div>
            <div v-else-if="scope.row.renewSubsidyPriceAll.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.renewSubsidyPriceAll.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible="recVisible"
      @close="recVisible = false"
      title="店员交易数据"
      width="1136px"
    >
      <div style="display: flex; width: 100%;height:32px;margin-bottom:20px">
        <div style="display: flex;">
          <div style="display: flex; align-items: center;margin-right:20px">
            <div style="width: 60px">店员名称</div>
            <el-select
              size="small"
              v-model="storeIdValue"
              clearable
              placeholder="请选择店员"
              @change="handleChangeInquiry"
            >
              <el-option
                v-for="item in storeList"
                :key="item.staffId"
                :label="item.staffName"
                :value="item.staffId"
              ></el-option>
            </el-select>
          </div>
          <el-button
            :type="dateTypeDia == '01' ? 'primary' : 'default'"
            size="small"
            @click="ClickDateTypeDia('01')"
            >今日</el-button
          >
          <el-button
            :type="dateTypeDia == '03' ? 'primary' : 'default'"
            size="small"
            @click="ClickDateTypeDia('03')"
            >本周</el-button
          >
          <el-button
            :type="dateTypeDia == '05' ? 'primary' : 'default'"
            size="small"
            @click="ClickDateTypeDia('05')"
            >本月</el-button
          >
        </div>
        <div style="display: flex;">
          <div class="el-date-time">
            <el-date-picker
              size="small"
              v-model="valueTimeDia"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </div>
          <div>
            <div class="btnType3" @click="getStaffRank(storeId)">
              <i class="el-icon-search"></i>查询
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 20px">
        <el-table
          max-height="550"
          :data="tableDataDia"
          v-loading="staffLoading"
          border
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" width="50">
          </el-table-column>
          <el-table-column
            prop="staffName"
            label="店员"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="inquiry"
            label="询价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deal"
            label="成交量"
            align="center"
          ></el-table-column>
          <el-table-column label="成交率" align="center">
            <template slot-scope="{ row }"> {{ row.dealRate }}% </template>
          </el-table-column>
          <el-table-column
            prop="dealAmount"
            label="累计成交额"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="grossProfit"
            label="毛利"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="depreciate"
            label="差异订单量"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
      :visible="storeVisible"
      @close="storeVisible = false"
      title="门店成交订单分析"
      width="1136px"
    >
      <div style="display: flex; width: 100%;height:32px;margin-bottom: 20px">
        <div style="display: flex;">
          <el-button
            :type="StoreDia == '01' ? 'primary' : 'default'"
            size="small"
            @click="ClickStoreDia('01')"
            >今日</el-button
          >
          <el-button
            :type="StoreDia == '03' ? 'primary' : 'default'"
            size="small"
            @click="ClickStoreDia('03')"
            >本周</el-button
          >
          <el-button
            :type="StoreDia == '05' ? 'primary' : 'default'"
            size="small"
            @click="ClickStoreDia('05')"
            >本月</el-button
          >
        </div>
        <div style="display: flex;align-items: center;">
          <div class="el-date-time">
            <el-date-picker
              size="small"
              v-model="ValueTimeStoreDia"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </div>
          <div>
            <div class="btnType3" @click="getStoreNum(1)">
              <i class="el-icon-search"></i>查询
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 20px">
        <el-table
          max-height="550"
          :data="tableDataDia2"
          border
          style="width: 100%"
          v-loading="dealLoading"
        >
          <el-table-column type="index" label="序号" align="center" width="50">
          </el-table-column>
          <el-table-column
            prop="orderNo"
            label="订单编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="staffName"
            label="店员"
            align="center"
          ></el-table-column>
          <el-table-column label="旧机名称" align="center">
            <template slot-scope="{ row }">
              <span style="margin-right: 5px">{{ row.spec }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最高报价" align="center">
            <template slot-scope="{ row }">
              <span style="margin-right: 5px">￥{{ row.maxPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dealPrice" label="成交价/商家" align="center">
            <template slot-scope="{ row }">
              <span style="margin-right: 5px">￥{{ row.dealPrice }}</span
              >{{ row.dealMerchantName }}
            </template>
          </el-table-column>
          <el-table-column label="毛利" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.dealProfit }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin: 20px 0">
          <el-pagination
            background
            :current-page="page.pageNum"
            :page-size="page.pageSize"
            layout="total, prev, pager, next,jumper"
            :total="page.total"
            class="el-pagination-c"
            @current-change="getStoreNum"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "phoneTable",
  props: ["partition","iid"],
  data() {
    return {
      dateTypeOption: [
        { label: '今日', value: '01' },
        { label: '昨日', value: '02' },
        { label: '本周', value: '03' },
        { label: '本月', value: '05' },
      ],
      addTimeText: '+添加对比时间',
      addTimeTrue: false,
      isThan: false,
      isThanTrue: false,
      thanValueTime: [],
      phoneTableLoading: false,
      staffLoading: false,
      dealLoading: false,
      valueTimeDia: "",
      ValueTimeStoreDia: "",
      dateTypeDia: "01",
      StoreDia: "01",
      storeVisible: false,
      storeId: "", //店员id
      storeIdValue: "", //弹窗id防止刚点击弹窗回显
      companyId: "", //商家id
      storeList: [],
      recVisible: false,
      valueTime: "",
      orderType: "01",
      dateType: "01",
      tableData: [],
      tableDataDia: [],
      tableDataDia2: [],
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      searchForm: {
        partitionval: "",
        storeId: "",
        tagId: "",
      },
      staffType:"",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    ...mapState({
      selectStoreList: (state) => state.tagsView.storeList,
      selectTagList: (state) => state.tagsView.tagList,
    }),
  },
  methods: {
    addTimeChoose () {
      this.addTimeText = '对比时间'
      this.addTimeTrue = true
      this.isThan = true
      this.isThanTrue = true
    },
    addTimeCancle () {
      this.addTimeText = '+添加对比时间'
      this.addTimeTrue = false
      this.isThan = false
      this.isThanTrue = true
      this.dateTime = []
      this.thanValueTime = []
      this.dateOn = '01'
      // this.getHomeData()
    },
    // 表格切换页面
    handleCurrentChange() {
      if (this.valueTime && this.valueTime.length) {
        this.dateType = "";
      } else if (!this.dateType) {
        this.dateType = "01";
      }
      const baseRequest = {
        thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
        thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
        companyId: this.iid,
        dateType: this.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime ? this.valueTime[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        queryType: "01", //查询类别 01 门店商家订单及交易统计：02，各回收商家交易数据统计
        startTime: this.valueTime ? this.valueTime[0] : "",
        xcxStaffId: this.searchForm.partitionval,
        storeId: this.searchForm.storeId,
        tagId: this.searchForm.tagId,
      };
      this.phoneTableLoading = true;
      _api.getStoreStatisticsRank(baseRequest).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data;
        }
        this.phoneTableLoading = false;
      });
    },
    exportExcel() {
      _api
        .getexcelStoreStatisticsRank({
          thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
        thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
          companyId: this.iid,
          xcxStaffId: this.searchForm.partitionval,
          storeId: this.searchForm.storeId,
          tagId: this.searchForm.tagId,
          dateType: this.dateType, //	今日 01 本周：03，本月：05
          endTime: this.valueTime ? this.valueTime[1] : "",
          orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
          queryType: "01", //查询类别 01 门店商家订单及交易统计：02，各回收商家交易数据统计
          startTime: this.valueTime ? this.valueTime[0] : "",
        })
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);

          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "各门店交易数据统计.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ClickOrderType(val) {
      this.orderType = val;
      console.log(this.orderType);
      this.handleCurrentChange();
    },
    ClickDateType(val) {
      this.valueTime = "";
      this.dateType = val;
      this.handleCurrentChange();
    },
    //店员交易数据切换年月日
    ClickDateTypeDia(val) {
      console.log("valueTimevalueTime");
      this.valueTimeDia = "";
      this.dateTypeDia = val;
      this.getStaffRank(this.storeId);
    },
    //////////////////////////////////////////
    // 累计数量弹窗
    ClickStoreDia(val) {
      this.ValueTimeStoreDia = "";
      this.StoreDia = val;
      this.getStoreNum(1);
      console.log(val);
    },
    ClickStoreDiaNum(row) {
      this.storeId = row.id;
      this.StoreDia = this.dateType;
      this.ValueTimeStoreDia = this.valueTime;
      this.getStoreNum();
      this.storeVisible = true;
      console.log(row, "累计数量弹窗");
    },
    //查询
    getStoreNum(val) {
      if (this.ValueTimeStoreDia && this.ValueTimeStoreDia.length) {
        this.StoreDia = "";
      } else if (!this.StoreDia) {
        this.StoreDia = "01";
      }
      const params = {
        dateType: this.StoreDia,
        storeId: this.storeId,
        startTime: this.ValueTimeStoreDia ? this.ValueTimeStoreDia[0] : "",
        endTime: this.ValueTimeStoreDia ? this.ValueTimeStoreDia[1] : "",
        pageNum: val ? val : this.page.pageNum,
        pageSize: this.page.pageSize,
        companyId: this.iid
      };
      this.dealLoading = true;
      _api.statisticsorderDeal(params).then((res) => {
        console.log(res);
        this.tableDataDia2 = res.data.records;
        this.page.total = res.data.total;
        console.log(this.page.total);
        this.dealLoading = false;
      });
    },
    //列表门店弹窗
    clickStoreTableDia(row) {
      this.storeId = row.id;
      this.storeIdValue = "";
      this.dateTypeDia = this.dateType;
      this.valueTimeDia = this.valueTime;
      this.getStoreList(row.id);
      this.getStaffRank(row.id);
      this.recVisible = true;
    },
    //店员下拉
    handleChangeInquiry(row) {
      console.log(row);
    },
    getStoreList(val) {
      const params = {
        storeId: val,
        companyId: this.iid
      };
      _api.getStaffList(params).then((res) => {
        console.log(res);
        this.storeList = res.data.records;
      });
    },
    //店员弹窗列表
    getStaffRank(val) {
      if (this.valueTimeDia && this.valueTimeDia.length) {
        this.dateTypeDia = "";
      } else if (!this.dateTypeDia) {
        this.dateTypeDia = "01";
      }
      const params = {
        companyId: this.iid,
        dateType: this.dateTypeDia,
        endTime: this.valueTimeDia ? this.valueTimeDia[1] : "",
        orderType: this.orderType,
        staffName: this.staffName,
        startTime: this.valueTimeDia ? this.valueTimeDia[0] : "",
        staffId: this.storeIdValue,
        storeId: val,
      };
      this.staffLoading = true;
      _api.getOrderDeal(params).then((res) => {
        console.log(res);
        this.tableDataDia = res.data;
        this.staffLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
 .add_time {
    color: #20A0FF;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    cursor: pointer;
  }
  .close_img {
    width: 16px;
    margin-left: 8px;
    margin-top: 2px;
  }
  .equationBlue {
    color: #20A0FF;
  }
  .equationGreen {
    color: #49A915;
  }
  .equationRed {
    color: #FF4949;
  }
.el-date-time {
  // /deep/ .el-input__inner {
  //   width: 200px !important;
  // }
  margin: 0 20px;
  /deep/ .el-range-separator {
    width: 10% !important;
  }
}
/deep/ .el-date-time .el-input__inner {
  width: 250px !important;
}
.el-seach {
  margin-right: 50px;
  /deep/ .el-input--small .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
  /deep/ .el-input__inner {
    width: 200px !important;
    border-radius: 18px !important;
  }
}

.phoneTable {
  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }
  .btnType1 {
    background: #0981ff;
    color: white;
    height: 32px;
    background: #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    padding: 0 9px;
  }
  .btnType-after1 {
    padding: 0 9px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #20a0ff;
    line-height: 32px;
  }
  .btnType2 {
    min-width: 30px;
    flex: 0.3;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after2 {
    // width: 33%;
    min-width: 40px;
    flex: 0.3;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType4 {
    width: 70px;
    // flex: 0.3;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after3 {
    width: 70px;
    // flex: 0.3;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType3 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #20a0ff;
    color: white;
    border-radius: 4px;
    margin-left: 20px;
    font-size: 14px;
  }
  .orderPagina {
    float: right;
    padding: 30px;
  }
  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }
  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }
  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }
  .el-button--default {
    //需要更改的按钮类型 type='default'
    background: #f9fbfd !important;
    border-color: #d9e6ff !important;
    color: #8492a6 !important;
  }
  //移入时按钮样式type='default'
  .el-button--default:hover {
    background: #fff !important;
    border-color: #45a3fc !important;
    color: #20a0ff !important;
    opacity: 0.8;
  }
  .el-button--primary {
    //需要更改的按钮类型 type='primary'
    background: #20a0ff !important;
    border-color: #20a0ff !important;
  }
  //移入时按钮样式 type='primary'
  .el-button--primary:hover {
    background: #20a0ff !important;
    border-color: #20a0ff !important;
    color: #fff !important;
    opacity: 0.8;
  }
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; //控制行数
    overflow: hidden;
  }
  .table_empty {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-el-heard {
    display: flex;
    justify-content: center;
    img {
      margin: 0px 0 -3px 3px;
    }
  }
}
</style>
